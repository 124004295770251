import firebase from 'firebase/app'
import 'firebase/analytics'

// This is usually the apiKey of firebaseConfig,
// but we currently had a verify-problem on megalol-staging firebase
// to generate dynamic short links. (we have a one-month-timeout on app.staging.mega.lol)
// So we use the live-megalol-project to generate them for now
// I've talked with Martin about this.
export const dynamicLinkAPIKey = 'AIzaSyC-kd3OYoxyUOdZLZWTWOuiIFYmnPglFyA'

// staging
// const firebaseConfig = {
//   apiKey: 'AIzaSyAUmlj1RGAjkRjLAXaU_9C6Bk7_4ld89OU',
//   authDomain: 'megalol-staging.firebaseapp.com',
//   databaseURL: 'https://megalol-staging.firebaseio.com',
//   projectId: 'megalol-staging',
//   storageBucket: 'megalol-staging.appspot.com',
//   messagingSenderId: '348473468092',
//   appId: '1:348473468092:web:58d3fa5add627dcbdedcb9',
//   measurementId: 'G-VVWD680WXD'
// }

// live
const firebaseConfig = {
  apiKey: 'AIzaSyC-kd3OYoxyUOdZLZWTWOuiIFYmnPglFyA',
  authDomain: 'megalol-636d3.firebaseapp.com',
  databaseURL: 'https://megalol-636d3.firebaseio.com',
  projectId: 'megalol-636d3',
  storageBucket: 'megalol-636d3.appspot.com',
  messagingSenderId: '1094203939122',
  appId: '1:1094203939122:web:92a3db315025dc88be9f2b',
  measurementId: 'G-BH2CG7Z8ZK'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export function enableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
}

export function disableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(false)
}
