import { firebaseApp } from './Firebase'
import 'firebase/remote-config'

export enum ConfigValue {
  appConfigHost = 'app_config_host',
  deepLinkDomain = 'deep_link_domain',
  deepLinkItem = 'deep_link_item',
  shareAndroidPackage = 'share_android_package',
  shareIOSId = 'share_ios_id',
  shareIOSPackage = 'share_ios_package',
  shopifyApiBaseUrl = 'shopify_api_base_url',
  shopifyApiToken = 'shopify_api_token',
  defaultDynamicLink = 'default_dynamic_link'
}

export const remoteConfig = firebaseApp.remoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 30000 // 3600000

remoteConfig.defaultConfig = {
  app_config_host: 'https://live.mega.lol',
  deep_link_domain: 'https://app.mega.lol',
  deep_link_item: 'https://mega.lol/detail/{itemId}',
  share_android_package: 'com.megalol.app',
  share_ios_id: '1273813626',
  share_ios_package: 'com.megalol.app',
  shopify_api_base_url: 'https://spruche-app-de.myshopify.com',
  shopify_api_token: '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',
  default_dynamic_link: 'https://app.mega.lol/getApp'
}

remoteConfig.fetchAndActivate()
